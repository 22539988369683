@mixin desktop {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin laptop1650min {
  @media (min-width: 1650px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 600px) {
    @content;
  }
}

// mobile first

@mixin sm {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin IIxl {
  @media (min-width: 1536px) {
    @content;
  }
}
