@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.searchHolder {
  border-radius: 10em;
  color: var(--field-text-color);
  display: grid;
  font-weight: 500;
  grid-template-columns: auto max-content max-content;
  height: 100%;
  width: 100%;

  @include phone {
    grid-template-columns: max-content auto max-content;
  }

  .searchInput {
    border-right: 1px solid $border-color;
    position: relative;
    width: 100%;

    input {
      appearance: none;
      border: 0;
      border-radius: 10em 0 0 10em;
      color: var(--field-text-color);
      display: inline-block;
      font-family: 'Quicksand', sans-serif;
      font-size: 1em;
      font-weight: 600;
      height: 100%;
      outline: none;
      padding: 0 2.3em;
      width: 100%;

      @include phone {
        font-size: 0.9em;
        padding: 0 0.5em;
      }

      &::placeholder {
        opacity: 1;
      }

      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration,
      &::-webkit-credentials-auto-fill-button {
        appearance: none;
      }

      &::-webkit-credentials-auto-fill-button {
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
        visibility: hidden;
      }
    }

    .clear {
      align-items: center;
      bottom: 0;
      cursor: pointer;
      display: flex;
      padding: 0.3em;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .selectInputHolder {
    align-items: center;
    border-right: 1px solid $border-color;
    color: var(--field-text-color);
    cursor: pointer;
    display: flex;
    grid-auto-flow: column;
    justify-content: center;

    .categoryValue {
      margin-right: 1em;

      @include phone {
        margin-right: 0.3em;
      }
    }
  }

  .searchLocation {
    align-items: center;
    border-radius: 0 10em 10em 0;
    color: var(--tagline-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0 1em;

    span:nth-child(1) {
      margin-right: 0.5em;
    }
  }

  .searchWrapper {
    align-items: center;
    display: grid;
  }

  .searchButton {
    border-radius: 50%;
    margin-right: 0.4em;

    @apply grid items-center justify-center bg-primary cursor-pointer h-8 w-8 lg:h-[2.8rem] lg:w-[2.8rem] text-center;

    span {
      color: #fff;
      font-size: 2em;

      @apply text-xl lg:text-3xl;
    }
  }
}

.navSearchHolder {
  grid-template-columns: auto auto max-content;

  .searchInput {
    input {
      font-size: 0.8em;
      padding: 0 1.8em;
    }
  }

  .searchLocation {
    font-size: 0.8em;
    padding: 0 0.8em;
  }

  .searchButton {
    height: 2.2em;
    width: 2.2em;

    @include phone {
      display: none;
    }

    span {
      font-size: 1.2em;
    }
  }
}

.searchCategories {
  background-color: $primary-light;
  border-radius: 0.2em;
  box-shadow: $box-shadow;
  color: var(--activity-text-color);
  overflow: hidden;
  width: 10em;

  .category {
    align-items: center;
    cursor: pointer;
    display: grid;
    font-size: 0.8em;
    font-weight: 600;
    gap: 0.8em;
    grid-template-columns: max-content auto;
    padding: 0.8em 1.2em;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &:hover,
    &.active {
      background-color: #fafafa;
      color: $primary-color;
    }
  }
}

.searchLocations {
  box-shadow: $box-shadow;
  width: 15em;

  input {
    background-color: #fff;
    height: unset;
    margin: 0;
    padding: 0.8em;
  }

  .suggestionsContainer {
    .suggestion {
      align-items: center;
      background-color: $primary-light;
      cursor: pointer;
      display: flex;
      font-size: 0.8em;
      padding: 0.8em;

      &.active {
        background-color: #fafafa;
      }
    }

    .suggestion:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    .loading {
      font-size: 0.7em;
      text-align: center;
    }
  }
}
