@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.detailNavigator {
  align-items: center;
  background-color: #fafafa;
  color: #929292;
  display: grid;
  font-size: 0.7em;
  font-weight: 600;
  gap: 1em;
  grid-template-columns: repeat(2, max-content);
  padding: 0.72em 0.9em;

  .viewAll {
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }
}

.businessLayoutWrapper {
  background-color: #fafafa;
  overflow: auto;

  .businessInformation {
    background-color: $primary-light;
    gap: 1rem;
    padding: 1rem;

    @include lg {
      gap: unset;
      height: 24em;
      grid-template-columns: 0.8fr 1fr 0.8fr;
      padding: 1.2em 2.1em 1.3em;
    }

    .businessIntro {
      padding: 0.45em 1.2em 0 0;
      overflow: auto;

      .businessInfo {
        display: grid;
        gap: 1.5em;
        grid-template-columns: max-content auto;

        .avatar {
          height: 5em;
          width: 5em;
        }

        .info {
          display: grid;
          gap: 0.2em;

          .name {
            font-size: 1.1em;
            font-weight: 600;
          }

          .username,
          .location {
            color: #999;
            font-size: 0.8em;
            font-weight: 600;
          }

          .rating {
            align-items: center;
            color: var(--detail-text-color);
            display: grid;
            font-weight: 600;
            gap: 0.6em;
            grid-template-columns: repeat(2, max-content);

            .overallRating {
              font-size: 0.75em;
              font-weight: 600;
            }

            .MuiRating {
              font-size: 1em;
            }
          }

          .follow {
            align-items: center;
            display: grid;
            font-size: 0.8em;
            gap: 1em;
            grid-template-columns: repeat(2, max-content);
            margin-top: 1.2em;

            .control {
              align-items: center;
              cursor: pointer;
              display: grid;
              gap: 1em;
              grid-template-columns: repeat(2, max-content);

              &.following {
                color: $primary-color;

                &:hover {
                  color: red;
                }
              }

              &:hover {
                color: $primary-color;
              }

              span:first-child {
                font-size: 1.4em;
              }
            }

            .followCount {
              font-size: 0.8em;
            }
          }
        }
      }

      .aboutBusiness {
        color: #999;
        font-size: 0.8em;
        font-weight: 500;
        line-height: 1.65em;
        margin-top: 2.4em;
        text-align: justify;
      }
    }

    .businessCovers {
      border-radius: 0.4em;
      height: 21em;
    }

    .businessDetails {
      @include lg {
        padding-left: 1.2em;
      }

      .detail {
        margin-bottom: 0.5em;

        .detailTitle {
          font-size: 0.85em;
          font-weight: 600;
        }

        .map {
          height: 14em;
          margin-top: 0.6em;
          margin-bottom: 2em;
        }

        .info {
          display: grid;
          gap: 1.1em;
          margin-top: 0.6em;

          .data {
            align-items: flex-start;
            color: #999;
            display: grid;
            gap: 1em;
            grid-template-columns: max-content auto;

            & > span {
              color: #d8d8d8;
              font-size: 1.2em;
            }

            .value {
              display: grid;

              span:last-child {
                font-size: 0.65em;
              }
            }

            .title {
              font-size: 0.8em;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .businessContentSection {
    display: grid;
    margin-top: 1.1em;
    overflow: auto;

    @include lg {
      grid-template-columns: 14.5em auto 22.5em;
    }

    .navigationBar {
      background-color: $primary-light;
      box-shadow: 0 2px 6px #0000000f;
      height: 100%;
      overflow: auto;
      display: none;

      @include lg {
        display: block;
      }

      .section {
        .title {
          cursor: pointer;
          font-size: 0.8em;
          padding: 0.95em 1em;
        }

        .links {
          padding: 1em;

          .link {
            .root {
              color: #cecece;
            }
          }
        }

        .title {
          border-bottom: 1px solid #f7f7f7;
          border-top: 1px solid #f7f7f7;
        }
      }
    }

    .content {
      background-color: $primary-light;
      box-shadow: 0 2px 6px #0000000f;
      height: 100%;
      overflow: auto;
      padding-bottom: 6em;

      .search {
        background-color: #fff;
        margin: 0.5rem 1rem 1rem;
        z-index: 20;

        @include lg {
          margin: 1em 2.2em 0 2.2em;
        }

        input {
          background-color: #fff;
          border-radius: 3em;
          height: 3em;
          padding: 0 1em 0 2.3em;
          width: 100%;

          @include lg {
            width: 32.2em;
          }
        }
      }

      .categoryBar {
        @apply grid lg:grid-flow-col lg:items-center gap-4 justify-center lg:justify-start lg:ml-10;

        .links {
          display: flex;
          width: max-content;
          @apply space-x-8 justify-self-center lg:justify-self-start;
        }

        .link {
          color: #37505c;
          cursor: pointer;
          font-size: 0.9em;
          font-weight: 600;
          opacity: 0.5;
          width: max-content;

          &.active {
            @apply text-primary;
            opacity: 1;
          }

          &:hover {
            @apply text-primary;
            opacity: 1;
          }
        }

        .actions {
          display: grid;
          gap: 1em;
          grid-template-columns: repeat(2, max-content);
          margin: 0 3.6em;

          @include lg {
            justify-self: end;
          }
        }
      }

      .sectionFilters {
        display: flex;
        margin: 0 0 0 2.5em;

        .link {
          color: #37505c;
          cursor: pointer;
          font-size: 0.75em;
          font-weight: 500;
          margin-right: 4.2em;
          opacity: 0.5;

          &.active {
            color: $primary-color;
            opacity: 1;
          }

          &:hover {
            color: $primary-color;
            opacity: 1;
          }
        }
      }
    }

    .sideContent {
      background-color: $primary-light;
      margin-left: 0.9em;
      overflow: auto;
      display: none;

      @include lg {
        display: block;
      }
    }
  }
}

.actionTitle {
  align-items: center;
  display: grid;
  font-size: 1.1em;
  font-weight: 600;
  gap: 1em;
  grid-template-columns: repeat(2, max-content);
  padding: 1em;

  span:first-child {
    cursor: pointer;
  }
}


.editPostWrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0.8em 0;
  width: 85%;

  @include phone {
    width: 94%;
  }
}